$(function(){
  // videoの取得
  var video = document.getElementById('top-movie');
  // SOUND ON ボタンを押した時
  var volup = document.getElementById('soundoff');
  volup.addEventListener('click', function () {
    video.muted = false;
    $('#soundon').addClass('selected');
    $('#soundoff').removeClass('selected');
  });
  // SOUND OFF ボタンを押した時
  var voldown = document.getElementById('soundon');
  voldown.addEventListener('click', function () {
    video.muted = true;
    $('#soundoff').addClass('selected');
    $('#soundon').removeClass('selected');
  });
});
$(window).on('load', function() {
	// $("#top-movie").get(0).play();

});

//
// //---------------------------------------------------
// // Movie Sound
// //---------------------------------------------------
//
// $('.p-mv__sound').find('a').on('click', function(){
// 	if(video.muted){
// 		video.muted = false;
// 		$(this).text('SOUND ON').addClass('is-active');
// 	} else {
// 		video.muted = true;
// 		$(this).text('SOUND OFF').removeClass('is-active');
// 	}
// });
//
//
//
//
//
// //---------------------------------------------------
// // Resize Actions
// //---------------------------------------------------
//
// var
// $productBg = $('.p-topproduct__bg'),
// $productLst = $('.p-topproduct__lst');
//
// $win.on('resize load', function(){
//
// 	var
// 	pLstMarginTop = 200,
// 	pLstH = $productLst.outerHeight(),
// 	pBgH = $productBg.outerHeight(),
// 	diff = Math.floor(pBgH - (pLstH + pLstMarginTop));
//
// 	enquire.register('screen and (min-width:769px)',{
// 		match: function() {
// 			if(diff < 0) {
// 				$productBg.css({'margin-bottom': diff * -1 + 'px'});
// 			} else {
// 				$productBg.removeAttr('style');
// 			}
// 		},
// 		unmatch: function() {
// 			$productBg.removeAttr('style');
// 		}
// 	})
// });



//All finished
